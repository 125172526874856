<template>
  <v-container fluid>
    <div>
      <div class="d-flex justify-space-between">
        <h1 class="text-h4">{{ $lang.header.generateEntity }}</h1>
      </div>
      <v-divider class="my-2"></v-divider>
      <v-row wrap no-gutters class="pb-4">
        <v-col
          v-if="!userCanEdit && !loading"
          cols="12"
        >
          <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider color="accent"></v-tabs-slider>
            <v-tab
              :key="0"
              data-cy="tab-details"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-permissions"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="pb-4 fill-height">
            <v-tab-item
              :key="0"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-row no-gutters class="py-1 mb-2">
                    <v-col
                      cols="12"
                    >
                      <div class="d-flex align-center">
                        <v-select
                          v-model="entityType"
                          disabled
                          outlined
                          dense
                          :items="formattedEntityTypes"
                          :label="$lang.labels.entityType"
                          required
                          hide-details
                          class="required-asterisk pr-2"
                          :rules="[v => !!v || $lang.labels.required]"
                          :readonly="!userCanEdit"
                          data-cy="entity-type"
                        ></v-select>
                        <v-autocomplete
                          v-model="generationData.credentialId"
                          data-cy="entity-credentialId"
                          outlined
                          dense
                          :items="credentials"
                          :loading="isLoadingCredentials"
                          :search-input.sync="searchCredentials"
                          clearable
                          hide-no-data
                          hide-details
                          item-text="name"
                          item-value="id"
                          :label="$lang.labels.credential"
                          :placeholder="$lang.actions.startTyping"
                          prepend-inner-icon="mdi-cloud-search-outline"
                          :readonly="!userCanEdit"
                          required
                          class="required-asterisk flex-grow-1 max-width copy-name-icon"
                          :rules="[v => !!v || $lang.labels.required]"
                          append-icon="mdi-vector-combine"
                          @click:append="copyCredential()"
                        ></v-autocomplete>
                        <v-btn
                          text
                          icon
                          class="ml-1"
                          color="primary"
                          :disabled="!generationData.credentialId"
                          data-cy="entity-credential-search"
                          @click="searchCredentialsFunction(searchCredentials)"
                        >
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          :disabled="!generationData.credentialId"
                          data-cy="entity-credential-open"
                          @click="openCredential()"
                        >
                          {{ $lang.actions.openTemplate }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row wrap no-gutters justify="space-between">
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1"
                    >
                      <div class="d-inline-flex max-width">
                        <v-text-field
                          v-model="generationData.persistenceName"
                          outlined
                          dense
                          :label="$lang.labels.existingPersistenceName"
                          required
                          class="required-asterisk copy-name-icon"
                          :rules="[v => !!v || $lang.labels.required, v => (v && v.length > 1 && v.length <= 64) || $lang.errors.nameTooShortOrLong64, v=> /^[A-Za-z0-9_]+$/g.test(v) || 'Only letters, numbers and underscore!']"
                          :persistent-hint="isEdit"
                          :readonly="!userCanEdit"
                          data-cy="entity-name"
                          append-icon="mdi-vector-combine"
                          @click:append="copyName()"
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between" class="mt-1">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex align-center">
                        <v-btn
                          color="secondary"
                          class="button-default-width"
                          :to="{ name: 'entities', params: { lang: $lang.current_lang } }"
                          data-cy="entity-back"
                        >
                          <v-icon
                            right
                            dark
                            class="ma-0"
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                      </div>

                    </v-col>
                    <v-col cols="6" class="text-right">
                      <v-btn
                        v-if="toEntityButtonId"
                        :disabled="lock"
                        color="success"
                        :loading="generateLoading"
                        class="mr-2 color-black"
                        data-cy="entity-generate-persistence"
                        @click="$router.push({name: 'entityEdit',
                                              params: {id: toEntityButtonId}})"
                      >
                        {{ $lang.actions.goToEntity }}
                      </v-btn>
                      <v-btn
                        :disabled="!valid || lock || !userCanEdit || Boolean(toEntityButtonId)"
                        color="info"
                        :loading="validateLoading"
                        class="color-black"
                        data-cy="entity-validate-persistence"
                        @click="validateEntityGeneration()"
                      >
                        {{ $lang.actions.validate }}
                      </v-btn>
                      <v-btn
                        :disabled="!valid || lock || !userCanEdit || Boolean(toEntityButtonId)"
                        color="warning"
                        :loading="generateLoading"
                        class="ml-2 color-black"
                        data-cy="entity-generate-persistence"
                        @click="generateEntity()"
                      >
                        {{ $lang.actions.generate }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-row wrap no-gutters>
                  <v-col cols="12">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-if="showValidateGenerateModal" v-model="showValidateGenerateModal" max-width="80%">
      <validate-generate-result-modal-fields-only
        :data="validateGenerateData"
        @closeDialog="resetValidateGenerateModal()"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import {
  getProcessCredentialsUsingGET as getCredentials,
  generateEntityFromPersistenceUsingPOST as generateEntity,
  validateEntityFromPersistenceUsingGET as validateEntityGeneration
} from '@/utils/api'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ValidateGenerateResultModalFieldsOnly from '@/pages/entities/ValidateGenerateResultModalFieldsOnly.vue'
import copy from 'copy-to-clipboard'
import { getRolesWithoutAuth } from '@/utils/helpers'

//TODO DRY somehow with EntitiesDetails and GenerationCard
export default {
  name: 'EntityGeneration',
  components: {
    UserRolesSelect,
    ValidateGenerateResultModalFieldsOnly
  },
  data() {
    return {
      tempKey: 0,
      tab: 0,
      valid: false,
      isEdit: false,
      loading: true,
      generateLoading: false,
      validateLoading: false,
      lock: true,
      toEntityButtonId: null,
      generationData: {
        credentialId: '',
        persistenceName: '',
        roles: []
      },
      showValidateGenerateModal: false,
      validateGenerateData: null,
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userCanEdit: false,
      history: { items: [], meta: {} },
      isSuperUser: false,
      userCanDelete: false,
      isLoadingCredentials: false,
      credentials: [],
      searchCredentials: '',
      entityType: 'JDBC',
      entityTypes: ['JDBC'/* , 'MONGODB' */]
    }
  },
  computed: {
    formattedEntityTypes() {
      return this.entityTypes.map((item) => {
        return { text: this.$lang.status[item], value: item }
      })
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    }
  },
  watch: {
    tab: {
      handler(val) {
        this.connectedResources = []
        this.history = { items: [], meta: {} }
        if (val === 5) {
          this.fetchHistory()
        } else if (val === 2) {
          this.extractChildRelations()
        }
      }
    },
    searchCredentials: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchCredentialsFunction(val)
        }
      }
    },
    entityType: {
      handler(val) {
        if (!this.lock) {
          this.generationData.credentialId = ''
          this.searchCredentials = ''
          this.credentials = []
        }
      }
    }
  },
  created() {
    this.createdFunction()
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    createdFunction() {
      this.lock = true
      this.toEntityButtonId = null

      let user = null

      if (localStorage.userData) {
        user = JSON.parse(localStorage.userData)

        this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
        this.userCanDelete = !!user.roles.find((x) => x.name === 'ENTITY_DELETER') || this.isSuperUser
      }

      this.init()
        .then(() => {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          this.loading = false
          setTimeout(() => this.lock = false, 100)
        })
    },
    openCredential() {
      if (!this.generationData.credentialId) return

      window.open(`/${localStorage.selectedLanguage || 'en'}/credentials/edit/${this.generationData.credentialId}`, '_blank')
    },
    searchCredentialsFunction(val = '') {

      if (!val || val.length < 2) return

      this.isLoadingCredentials = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      obj.type = this.entityType

      getCredentials(obj)
        .then((res) => {
          if (res.status !== 200) {
            if (res.response.status === 403) {
              this.addSnackbar({
                message: this.$lang.errors.noPermission,
                timeout: 5000,
                color: 'error'
              })
            }
            this.isLoadingCredentials = false

            return
          }
          this.credentials = res.data.data.items
          this.isLoadingCredentials = false
        })
        .catch((err) => {
          this.isLoadingCredentials = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    copyName() {
      if (this.generationData.persistenceName) {
        copy(this.generationData.persistenceName)

        this.addSnackbar({
          message: this.$lang.success.copiedClipboard,
          timeout: 5000,
          color: 'success'
        })
      } else {

        this.addSnackbar({
          message:  this.$lang.errors.nothingToCopy,
          timeout: 5000,
          color: 'error'
        })
      }
    },
    copyCredential() {
      const credential = this.credentials.find((x) => x.id === this.generationData.credentialId)

      if (credential) {
        copy(credential.name)

        this.addSnackbar({
          message: this.$lang.success.copiedClipboard,
          timeout: 5000,
          color: 'success'
        })
      } else {

        this.addSnackbar({
          message: this.$lang.errors.nothingToCopy,
          timeout: 5000,
          color: 'error'
        })
      }
    },
    init() {
      return new Promise((resolve) => {
        getRolesWithoutAuth()
          .then((roles) => {
            this.allRoles = roles
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    async generateEntity() {

      if (this.editRolesIds.length < 1 && !this.isSuperUser) {
        this.addSnackbar({
          message: this.$lang.errors.editRoleCreate,
          timeout: 5000,
          color: 'error'
        })

      }

      this.lock = true
      this.generateLoading = true

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      this.generationData.roles = tempRoles

      try {
        const res = await generateEntity({ body: this.generationData })

        if (res && res.status !== 200) {
          const errorMessage = res?.response?.data?.statusText || this.$lang.errors.entityGenerate

          if (res.status === 403) {
            this.addSnackbar({
              message: this.$lang.errors.noPermission,
              timeout: 5000,
              color: 'error'
            })
          } else {
            this.addSnackbar({
              message: errorMessage,
              timeout: 5000,
              color: 'error'
            })
          }
          this.lock = false
          this.generateLoading = false

          return
        }
        
        this.addSnackbar({
          message: this.$lang.success.entityGenerated,
          timeout: 5000,
          color: 'success'
        })

        this.toEntityButtonId = res.data.data.id
        this.lock = false
        this.generateLoading = false
        
      } catch (err) {
        this.lock = false
        this.generateLoading = false
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
      }
    },
    async validateEntityGeneration() {
      this.resetValidateGenerateModal()
      this.validateLoading = true
      validateEntityGeneration({
        credentialId: this.generationData.credentialId,
        persistenceName: this.generationData.persistenceName,
        roles: this.generationData.roles
      })
        .then((res) => {
          if (res.status !== 200) {
            const errorMessage = res?.response?.data?.statusText || this.$lang.errors.entityGenerate

            if (res.response.status === 403) {
              this.addSnackbar({
                message: this.$lang.errors.noPermission,
                timeout: 5000,
                color: 'error'
              })
            } else {
              this.addSnackbar({
                message: errorMessage,
                timeout: 5000,
                color: 'error'
              })
            }
            this.validateLoading = false

            return
          } else if (res?.data?.data?.version?.entity) {
            this.validateLoading = false
            this.addSnackbar({
              message: this.$lang.errors.entityWithThisNameExists,
              timeout: 5000,
              color: 'error'
            })

            return
          } else {
            this.validateGenerateData = res.data.data
          }

          this.validateLoading = false
          this.showValidateGenerateModal = true
        })
        .catch((err) => {
          this.validateLoading = false
          const errMessage = err || this.$lang.errors.entityValidation

          this.addSnackbar({
            message: errMessage,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    resetValidateGenerateModal() {
      this.showValidateGenerateModal = false
      this.validateGenerateData = null
    }
  }
}
</script>

<style>
.copy-name-icon .v-icon{
  color: #009fb7;
}

</style>
